import { type } from "os";

export const enum Approaches {
    RetrieveThenRead = "rtr",
    ReadRetrieveRead = "rrr",
    ReadDecomposeAsk = "rda"
}
export interface ITrackSearch {
    status: string;
    name: string;
    result: string | null;
}

export interface ITrackSearch {
    status: string
    name: string
    result: string | null
  }

export const enum RuleSearch {
    knowledge = "Tri thức ngành",
    reference = "Nội dung tham khảo"
}
export const enum OptionHotIssue {
    template = "answer_by_template",
    LLM_choosefirstResult = "summary_first_result",
    LLM_chooseResult = "summary_use_llm_selection",
    LLM_chooseRecomment = "summary_use_hybrid"
}

export const enum DraftStatus {
    sent = "Đã gửi đi",
    unsent = "Chưa gửi"
}

export const enum ItemsDraftStatus {
    all = "Tất cả",
    processing = "Đang xử lý",
    pending = "Chờ duyệt",
    processed = "Đã xử lý",
    noprocess = "Chưa xử lý"
}

export const enum RuleChat {
    chat = "Hội thoại",
    draft = "Đóng góp"
}
export interface ITrackSearch {
    status: string;
    name: string;
    result: string | null;
}

export type AskRequestOverrides = {
    id?: string;
    semantic_ranker?: boolean;
    semantic_captions?: boolean;
    exclude_category?: string;
    top?: number;
    temperature?: number;
    temperature_gen_query?: number;
    reference_tab?: boolean;
    prompt_template?: string;
    promptTemplatePrefix?: string;
    promptTemplateSuffix?: string;
    suggest_followup_questions?: boolean;
    use_llm_filter_reference?: boolean;
    use_context_synthesis?: boolean;
    semantic_law_retriever?: boolean;
    fulltext_search_retriever?: boolean;
    no_prompt?: boolean;
    no_search_engine?: boolean;
    rerank_retrieve?: boolean;
    confirm_decision?: boolean;
    use_llm_filter: boolean,
    suggestion_by_llm: boolean,
    no_gen_query?: boolean;
    chatgpt_model?: string;
    chatgpt_model_gen_query?: string;
    retrieval_mode?: string;
    prefix_prompt: string;
    follow_up_prompt: string;
    query_prompt: string;
    graph_prompt: string;
    ai_speech_prompt: string;
    summary_prompt: string;
    selection_prompt: string;
    option_hot_issue: string;
    conversation_id: string;
    need_search_specific?: boolean;
    is_new_semantic?: boolean;
    continue_step_search?: number;
    expanded_search: boolean;
    type_search_engine?: string
};

export type AskRequest = {
    question: string;
    approach: Approaches;
    overrides?: any;
};

export type ButtonFollowupAnswer = {
    type: string;
    text: string;
    text_display: string;
};

export type listCitationHot = {
    id: string;
    name: string;
};

export type relatedIssueFaqs = {
    id: string;
    name: string;
    period: string;
};
export type relatedMinisterAnswerFaqs = {
    id: string;
    issueName: string;
};
export type relatedPetitionFaqs = {
    id: string;
    petitionContent: string;
};

export type entryAllFaqs = relatedIssueFaqs | relatedMinisterAnswerFaqs | relatedPetitionFaqs;

export type issue_buttons = {
    display_name: string;
    normalized_names: string[];
    type: string;
};
export type generic_buttons = {
    type: string;
    title: string;
    description: string;
    name: string;
    payload: string;
};
export type charts = {
    label: string;
    value: number;
    percent: number
};

export type multiple_data_points = {
    title: string;
    content: string;
    url: string | null;
    type: string;
    reference_urls: string[];
    document_citations: DocumentCitation[];
    id: string
    validity_status: TStatus;
};

export type DocumentCitation = {
    id: string;
    name_display: string;
    url_display: string;
    type: string;
    validity_status: TStatus;
}

export type DocumentCitationDraf = {
    documentId?: string;
    type?: string;
    documentTitle?: string;
    articleId?: string;
    articleTitle?: string;
    clauseTitle?: string;
    pointTitle?: string;
    urlDisplay?: string;
}
export type AskResponse = {
    show_button_reference: boolean;
    searching_type: string;
    use_list_citation: boolean;
    type_chart: string;
    datetime: string;
    recommend_buttons: ButtonFollowupAnswer[];
    is_used_history: boolean;
    process_images: string[];
    data_chart: charts[];
    title_chart: string;
    unit_chart: string;
    faqs: any[];
    global_articles: any[];
    specific_articles: any[];
    total: any[];
    is_expanded_search: boolean;
    question: string;
    gen_query: any;
    is_legal: boolean;
    documents: string[];
    recommends: string[];
    buttons: ButtonFollowupAnswer[];
    rerank_scores: string[];
    reference_urls: string[];
    reference_docs: string[];
    list_citation: listCitationHot[];
    answer: string;
    thoughts: string | null;
    debugs?:any;
    data_points: string[];
    multiple_data_points: multiple_data_points[];
    semantic_scores: string[];
    error?: string;
    citation?: any;
    data?: any;
    overrides?: any;
    id: string;
    not_show_citation?: boolean;
    need_search_specific: boolean;
    reference_titles: string[];
    use_fixed_citation: boolean;
    sfdt?: string;
    issue_buttons?: issue_buttons[];
    is_markdown: boolean;
    track_search: ITrackSearch[];
    continue_step_search: number;
    expanded_search: boolean;
    use_expanded_faq: any;
    working_process_steps?: any[];
    star: number;
    continue_step_search_title: string;
    is_allow_summary: boolean;
    question_summary: string;
    status: TStatus;
};

export type TStatus = "ACTIVE" | "EXPIRED"  | "VALID" | "NOT_YET_ACTIVE" | "NEAR_EXPIRATION";

export type TLabel = 'success' | 'error' | 'warning';

export type AskResponsePrompt = {
    follow_up_prompt: string;
    prefix_prompt: string;
    query_prompt: string;
    graph_prompt: string;
    summary_prompt: string;
    selection_prompt: string;
    ai_speech_prompt: string;
    is_apply: "";
    id: "";
    error?: string;
};

export type resExpanded = {
    isExpandedFaq: boolean;
    title: string;
    content: string;
    isSearchFaq: boolean;
};

export type AskResponseDefaultQuestion = {};

export type ChatTurn = {
    user: string;
    bot?: string;
    gen_query?: string;
};

export interface IEditComment{
    id: string,
    comment: string
}

export type TActions =  "APPROVE_MISSION" | "DENY_APPROVE"
export enum ActionState {
    APPROVE_MISSION = 'APPROVE_MISSION',
    DENY_APPROVE = 'DENY_APPROVE',
}

export type ChatRequest = {
    history?: ChatTurn[];
    approach?: Approaches;
    overrides?: any;
    query?: string;
    fullname?: string;
    username?: string;
    display_name?: string;
};

export type ChatRequestVtcc = {
    query?: string;
};

export type CitationsRequest = {
    content: string;
};

export type CitationsByIdRequest = {
    id: string;
    typeArticle: string;
};

export type CitationsRequestHotIssue = {
    content: string;
    id: string;
    type: string;
};

export type CitationsCompareRequest = {
    word: string;
    textList?: string[];
};
export type ISelectedField = {
    value: string;
    label: string;
};
export interface IFeedback {
    field: string;
    question: string;
    correctionAnswer: string;
    documentCitation?: DocumentCitationDraf[];
}

export interface IDraft extends IFeedback {
    createdAt: string;
    messageId: string;
    id: string;
}
export interface IDraftContribute extends IFeedback {
    isDraft: boolean;
    messageId: string;
    id?: string;
}
export interface requestAddFeedback extends IDraftContribute {
    userId: string;
}

export type PromptRequest = {
    bot: string;
    password: string;
    prefixPrompt: string;
    followUpPrompt: string;
    queryPrompt: string;
    graphPrompt: string;
    idPrompt: string;
    is_apply: boolean;
};
export type DefaultQuestionRequest = {
    type: string;
    data: string[];
    id: string;
};
export type DocumentSupportResponse = {
    id?: string;
    name?: string;
    totalLaw?: number;
    lawDocuments?: any;
    error?: any;
    data?: any;
};

export type DocumentsAnswer = {
    name: string;
    url: string;
};

export type Suggestion = {
    id: string;
    isSemantic: number;
    word: string;
    wordNormalize: string;
};

export type EnterPrise = {
    createdAt: string
    updatedAt: string
    createdBy: any
    updatedBy: any
    id: string
    name: string
    unit: string
    province: any
    publicAdministration: PublicAdministration
    typeSearch: string
    isSearchGlobal: number
    isSearchSpecific: number
    typePriority: string
    typeLLM: string
    scoreGlobal: number
    scoreSpecific: number
    sizeGlobal: number
    sizeSpecific: number
    size: number
    isFaqPolitic: number
    isFaqOrganization: number
    enterprisePromptId: any
    chatSamples: string[]
    whiteListId: any
    faqSemanticUUID: any
    chatSamplesNew: any
    chatSamplesOther: any
    level: any
    parentId: any
    unitLevel2Id: any
    unitLevel2Name: any
    unitLevel1Id: any
    unitLevel1Name: any
    domain: any
    storage: string
    deleted: boolean
  }
  
  export interface PublicAdministration {
    createdAt: any
    updatedAt: any
    createdBy: any
    updatedBy: any
    id: string
    title: string
    type: number
    parentId: any
    deleted: boolean
  }
  

export type SummaryResponse = {
    answer: string;
};

export enum ESearchEngine {
    Google = 'Google Search',
    DuckDuckGo = 'DuckDuckGo Search',
    Hybrid = 'Hybrid Search',
  }