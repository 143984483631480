import moment from "moment";

export const eventDateConvertedV2 = (dateIncoming: any) => {
    if (dateIncoming) {
        moment.locale('vi')
        const dateConverted = new Date(dateIncoming)
        const dt = moment(dateConverted, 'YYYY-MM-DD HH:mm:ss')
        const dayName = dt.format('dddd')
        const dayNameShort = dayName.substring(0, 3)
        const fullDate = dt.format('DD/MM/YYYY')
        const timeDate = dt.format('HH:mm')
        return { date: fullDate, dayName: dayNameShort, time: timeDate }
    }
}

export const eventDateConverted = (dateIncoming: any) => {
    if (dateIncoming) {
        moment.locale("vi");
        const dateConverted = new Date(dateIncoming);
        const dt = moment(dateConverted, "YYYY-MM-DD HH:mm:ss");
        const dayName = dt.format("dddd");
        const dayNameShort = dayName.substring(0, 3);
        const fullDate = dt.format("DD/MM/YYYY");
        const timeDate = dt.format("HH:mm");
        const tempTimeAgo = moment(dt).fromNow()
        const timeAgo =
          tempTimeAgo === 'một phút tới'
            ? 'vừa xong'
            : tempTimeAgo === 'vài giây tới'
            ? 'vài giây'
            : tempTimeAgo === 'một phút trước'
            ? '1 phút'
            : tempTimeAgo.replace(' trước', '')
        return { date: fullDate, dayName: dayNameShort, time: timeDate, timeAgo };
    }
};

export const eventTime = () => {
        moment.locale('vi')
        const hour = moment().get('hour');
        const minute = moment().get('minute');
        return { hour: hour, minute: minute }
}

export const checkTimeTosendHistory = (lastChatTime: Date | null): boolean => {
    
    const currentTime = new Date();

    // Chuyển đổi lastChatTime thành chuỗi ISO nếu không phải null
    const lastChatTimeString = lastChatTime ? lastChatTime.toISOString() : null;
    
    // Kiểm tra xem đã quá 15 phút chưa
    const fifteenMinutes = 15 * 60 * 1000; // 15 phút tính bằng mili giây

    // Chuyển đổi lastChatTime thành Date nếu không phải null
    const lastChatDate = lastChatTimeString ? new Date(lastChatTimeString) : null;

    // Kiểm tra điều kiện
    const isOlderThan15Minutes = lastChatDate && (currentTime.getTime() - lastChatDate.getTime() > fifteenMinutes);

    // Nếu đã quá 15 phút, không gửi lịch sử
    return !isOlderThan15Minutes;
}
