import { SnackbarProvider } from 'notistack';
import React from 'react';

const CustomSnackbarProvider = ({ children }: { children: React.ReactNode }) => {
  const notistackRef = React.createRef<SnackbarProvider>();

  return (
    <SnackbarProvider
      maxSnack={3}
      ref={notistackRef}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export default CustomSnackbarProvider;
