import { ComponentProps, ReactNode } from "react";
import { ButtonProps } from "./Button";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "./Dialog";
import Typography from "./Typography";

type Props<S> = Omit<ComponentProps<"div">, "children"> & {
    maxWidth?: any,
    children: any,
    show: boolean,
    title?: string,
    size?: "sm" | "lg" | "xl" | undefined,
    disabled?: boolean,
    closeModalConfirm: () => void,
    handleConfirm: () => void,
    footer?: ButtonProps[] | null,
    header?: ReactNode
};

export const ModalConfirm = function ModalConfirm<S>(props: Props<S>) {
    const { maxWidth = "md", children, show, title, closeModalConfirm, handleConfirm, size, disabled, footer, header, ...rest } = props;

    return (
        <>
            <Dialog open={show} onClose={closeModalConfirm} fullWidth={true} maxWidth={maxWidth} aria-labelledby="confirm-dialog-title" aria-describedby="confirm-dialog-description" sx={{ zIndex: 10000000 }}>
                <DialogTitle onClose={closeModalConfirm}>
                    <Typography variant="h5" size="text-xl" weight="semibold">
                        {
                            header ? header : title
                        }
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    {children}
                </DialogContent>
                <DialogActions
                    buttons={footer ? footer : [
                        {
                            children: 'Hủy',
                            variant: 'outlined',
                            buttonSize: 'sm',
                            color: 'error',
                            type: 'button',
                            fullWidth: false,
                            onClick: closeModalConfirm,
                        },
                        {
                            children: 'Xác nhận',
                            variant: 'contained',
                            buttonSize: 'sm',
                            color: 'error',
                            fullWidth: false,
                            onClick: handleConfirm,
                            disabled: disabled,
                            type: 'submit',
                        },
                    ]}
                />
            </Dialog>
        </>
    )
};
