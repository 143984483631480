import { useState, useEffect, useRef } from "react";
import { cloneDeep } from 'lodash'
import { observer } from "mobx-react-lite";
import styles from "./ChatWithHistory.module.css";
import { ChatHistory } from "../../components/Chat/ChatHistory"
import Chat from "../chat/Chat"
import { DraftStatus, IDraftContribute, IFeedback, likeUnlikeChatSession, listAllChatSession, RuleChat, saveFeedback } from "../../api";
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import eventBus from "../../plugins/EventBus"
import CloseSidebar from "../../assets/Group.svg"
import { useRedirectChat } from "../../hooks/useRedirectChat";
import { useParams } from "react-router-dom";
import { useStore } from "../../hooks/useStore";
import { toggleSession } from "../../utils/toggleSession";
import { ModalContribute } from "../../components/Answer";
import showToasty from "../../hooks/useNotify";
import { uuid } from "../../utils/string";
import { ModalProvider } from "../../components/context/ModalContext";

const WIDTH_DISPLAY_SIDEBAR = 1194

export const ChatWithHistory = observer(({ setHeader, isConfigPanelOpen, setIsConfigPanelOpen, messageRequest, setMessageRequest }: any) => {
    const {
        rootStore: { sessionChatsStore, draftStore, configurationStore },
    } = useStore();
    const { navigate } = useRedirectChat()
    const params = useParams();

    // loading chat session
    const [loadingChats, setLoadingChat] = useState<boolean>(false);

    const [chatSessionList, setChatSessionList] = useState<any>([])
    const [allChatBySessionName, setAllChatBySessionName] = useState<any>([])
    const [idChatBySessionName, setIdChatBySessionName] = useState<string>("")
    const [isDisableNewchatButton, setIsDisableNewchatButton] = useState<boolean>(true);
    const [idNewContribute, setIdNewContribute] = useState<string>('')
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight - 230);
    useEffect(() => {
        const handleWindowResize = () => { setWidth(window.innerWidth), setHeight(window.innerHeight - 230) };
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);
    const isMobile = width <= WIDTH_DISPLAY_SIDEBAR;
    const [isOpen, setIsOpen] = useState(false)
    const [openModalContribute, setOpenModalContribute] = useState<boolean>(false)
    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
        if (isMobile) {
            const temp = document.querySelector("#historyContainer")
            temp?.classList.remove("historyContainerClose");
            const bar = document.querySelector(".activeNav")
            if (!bar) return
            toggleSession()
        }
    }
    const onOpenNavbarMobile = () => {
        setIsOpen(true)
    }
    const onCloseNavbarMobile = () => {
        setIsOpen(false)
    }
    useEffect(() => {
        if (params.id) {
            getActiveChat()
            // configurationStore.SET_IS_HISTORY(false)
        }
    }, [params.id])

    const getActiveChat = () => {
        const chatId = params && params.id ? params.id : ''
        sessionChatsStore.getIdParams(chatId)
        const conversationTmp = cloneDeep(sessionChatsStore.getSessionChat)
        conversationTmp.map((item: any, key: any) => {
            if (chatId === item.id) {
                item.active = true
            } else {
                item.active = false
            }
            return item;
        });
        setChatSessionList(conversationTmp);
    }

    useEffect(() => {
        if (sessionChatsStore.getSessionChat && sessionChatsStore.getSessionChat.length) {
            getActiveChat()
        } else {
            setChatSessionList(sessionChatsStore.getSessionChat);
        }
    }, [sessionChatsStore.getSessionChat])


    const chatBySession = async (chat: any, e: any) => {
        if (e?.target?.classList?.value?.includes('isMoreAction') ||
            e?.target?.classList?.value?.includes('isMoreActionDot') ||
            e?.target?.classList?.value?.includes('dropdownitem') ||
            e?.target?.classList?.value?.includes('dropdownitemSvg') ||
            e?.target?.classList?.value?.includes('dropdownitem-text') ||
            e?.target?.nodeName === 'path'
        ) return
        setIdChatBySessionName(chat?.id || "")
        if (sessionChatsStore.ruleChat === RuleChat.chat) {
            navigate(chat?.id ?? '')
            getActiveChat()
            setIsOpen(false)
        } else {
            setOpenModalContribute(true)
        }
    }
    const closeModalContribute = () => {
        setIdChatBySessionName('')
        setIdNewContribute('')
        setOpenModalContribute(false)
    }

    const newContribute = () => {
        setIdNewContribute(uuid)
        setOpenModalContribute(true)
    }

    useEffect(() => {
        setHeader(false)
        setLoadingChat(true)
        async function fetchMyAPI() {
            sessionChatsStore.fetchSessionChat()
                .then()
                .finally(() => {
                    setLoadingChat(false)
                })
        }
        fetchMyAPI()
    }, [])

    const clearAllSessionChat = () => {
        sessionChatsStore.removeAllChat()
        setChatSessionList(sessionChatsStore.getSessionChat)
    }

    const firstUpdate = useRef(false);
    useEffect(() => {
        if (firstUpdate.current) {
            return;
        }
        eventBus.on("reloadChatPinUnpin", (data: any) => {
            reloadChatPinUnpin(data)
        });
        eventBus.on("clearchatNavbar", (data: any) => {
            clearchatNavbar(data)
        });
        eventBus.on("reloadMessageChat", (data: any) => {
            reloadMessageChat(data)
        });
        eventBus.on("onOpenNavbarMobile", (data: any) => {
            onOpenNavbarMobile()
        });
        eventBus.on("reloadChatByClearAll", (data: any) => {
            clearAllSessionChat()
        });
        eventBus.on("closeNavbar", (data: any) => {
            onCloseNavbarMobile()
        });
        if (chatSessionList && chatSessionList.length) {
            eventBus.dispatch('onReloadShowIcon', {})
        } else {
            eventBus.dispatch('onReloadShowIconFalse', {})
        }
        // citationsStore.addCitationLinks([])
        return () => {
        };

    }, [chatSessionList])

    const clearchatNavbar = (data: any) => {
        sessionChatsStore.updateMessagesChat(data.id, '')
    }
    const reloadMessageChat = (data: any) => {
        sessionChatsStore.updateMessagesChat(data.id, data.firstMessage)
    }

    const reloadChatPinUnpin = (data: any) => {
        const conversationTmp = cloneDeep(chatSessionList)
        conversationTmp.map((item: any, key: any) => {
            if (item.id === data.id) {
                item.pinned = data.pinned
            }
            return item;
        });
        // setChatSessionList(conversationTmp);
        sessionChatsStore.reloadChatPinUnpin(conversationTmp)
    }

    const draftContribute = async (draft: IDraftContribute) => {
        const {data} = await saveFeedback(draft)
        sessionChatsStore.ADD_DRAFT(data)
    }
    const saveDraftSucces = () => {
        showToasty("Lưu bản nháp thành công", 'success');
        closeModalContribute()
    }
    const sendFeedback = async (feedback?: IFeedback) => {
        const request: any = {
            isDraft: false,
            id: idChatBySessionName,
            ...feedback
        }
        try {
            const {data} = await saveFeedback(request)
            sessionChatsStore.ruleChat === RuleChat.draft && draftStore.activeDraft === DraftStatus.sent ? sessionChatsStore.ADD_DRAFT(data) : sessionChatsStore.SET_DRAFT(idChatBySessionName)
            closeModalContribute()
            showToasty("Gửi đóng góp thành công", 'success');
        } catch (error) {
            showToasty("Gửi đóng góp thất bại, vui lòng thử lại sau", 'error');
            closeModalContribute()
        }

    }
    return (
        <div className={styles.allContainerChat}>
            <>
                {!isMobile ? (

                    <ChatHistory 
                        setIsOpen={() => setIsOpen(false)} 
                        height={height} 
                        setIdChatBySessionName={setIdChatBySessionName} 
                        reloadChatPinUnpin={reloadChatPinUnpin} 
                        isMobile={isMobile} 
                        setChatSessionList={setChatSessionList} 
                        isDisableNewchatButton={isDisableNewchatButton} 
                        chatBySession={chatBySession} 
                        chatSessionList={chatSessionList} 
                        onNewContribute={newContribute}
                        loadingChats={loadingChats}
                    />
                ) : <>

                    <div className={`${isMobile ? styles.iconOpenChatMobile : ''}`} style={{ cursor: 'pointer', transform: 'rotate(180deg)', height: '50px', marginInlineStart: '12px' }} onClick={toggleDrawer}>
                        <img src={CloseSidebar} />
                    </div>
                    <Drawer
                        open={isOpen}
                        onClose={toggleDrawer}
                        direction='left'
                        className='drawerHistory'
                    // size={'404px'}
                    >
                        <div>
                            <ChatHistory 
                                setIsOpen={() => setIsOpen(false)} 
                                height={height + 60} 
                                setIdChatBySessionName={setIdChatBySessionName} 
                                reloadChatPinUnpin={reloadChatPinUnpin} 
                                setChatSessionList={setChatSessionList} 
                                isMobile={isMobile} 
                                isDisableNewchatButton={isDisableNewchatButton} 
                                chatBySession={chatBySession} 
                                chatSessionList={chatSessionList} 
                                onNewContribute={newContribute}
                                loadingChats={loadingChats}
                            />
                        </div>
                    </Drawer>
                </>
                }

            </>
            <Chat setHeader={(val: any) => setHeader(val)}
                idChatBySessionName={idChatBySessionName}
                setIsDisableNewchatButton={setIsDisableNewchatButton}
                allChatBySessionName={allChatBySessionName}
                setChatSessionList={setChatSessionList}
                chatSessionList={chatSessionList}
                messageRequest={messageRequest}
                setMessageRequest={setMessageRequest}
                isConfigPanelOpen={isConfigPanelOpen}
                setIsConfigPanelOpen={setIsConfigPanelOpen}
                setIdChatBySessionName={setIdChatBySessionName}
            />
            <ModalProvider>
                <ModalContribute
                    openContribute={openModalContribute}
                    closeFeedback={closeModalContribute}
                    draftContribute={(draft) => draftContribute(draft)}
                    draftId={idChatBySessionName}
                    newMessageId={idNewContribute}
                    sendFeedback={sendFeedback}
                    closeModalContribute={saveDraftSucces}
                />
            </ModalProvider>

        </div>
    );
});
