import { Label, Stack, TextField } from "@fluentui/react";
import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { DraftById } from "../../api";
import { DraftStatus, IDraftContribute, IFeedback, ISelectedField, RuleChat } from "../../api/models";
import { useStore } from "../../hooks/useStore";
import { ModalConfirm } from "../Ui/Modal";
import styles from "./Answer.module.css";
import { CitationForm } from './citation-feedback/CitationForm';
import { DropDownField } from "./DropDownField";
import { ButtonProps } from "../Ui/Button";

interface Props {
    newMessageId?: string
    messageId?: string
    draftId?: string
    sanitizedAnswerHtml?: any
    openContribute: boolean
    genQuery?: string,
    sendFeedback?: (feedback: IFeedback) => void
    draftContribute: (request: IDraftContribute) => void
    closeModalContribute?: () => void,
    closeFeedback: () => void
}

export const ModalContribute = observer(({
    newMessageId,
    messageId,
    draftId,
    sanitizedAnswerHtml,
    openContribute,
    genQuery,
    sendFeedback,
    draftContribute,
    closeModalContribute,
    closeFeedback }: Props) => {
    const {
        rootStore: { sessionChatsStore, draftStore },
    } = useStore();

    const [fieldSelected, setFieldSelected] = useState<ISelectedField | null>(null)
    const [question, setQuestion] = useState<string>(genQuery ? genQuery : '')
    const [correctionAnswer, setAnswer] = useState<string>('')
    const [showConfirm, setShow] = useState<boolean>(false)
    const [isApproveStatus, setIsApproveStatus] = useState<boolean>(false)

    const [documentCitation, setDocumentCitation] = useState<any>([])
    const [isEmptyDocCitation, setIsEmptyDocCitation] = useState<boolean>(false)

    const isEmptyAnswer = isEmpty(correctionAnswer);

    const checkEmptyDocCitation = () => {
        if(isEmpty(documentCitation)) {
            return true
        }
        for(const docCitation of documentCitation) {
            const isEmptyDocTitle = docCitation?.type === 'law' && isEmpty(docCitation?.law?.documentId)
            const isEmptyUrl = docCitation?.type === 'url' && isEmpty(docCitation?.urlDisplay)

            if (isEmptyDocTitle || isEmptyUrl) {
                return true;
            }
        }
        
        return false;
    }


    const handleSubmit = () => {
        const field: string = fieldSelected?.label ? fieldSelected?.label : '';
        const documentCitationReq = documentCitation.map((item: any) => {
            let tmpItem = {
                ...item,
                ...item.law,
                ...item.article,
            }
            delete tmpItem['law'];
            delete tmpItem['article'];
            delete tmpItem['value'];

            return tmpItem;
        });
        const request: IFeedback = {
            field, question, correctionAnswer, documentCitation: documentCitationReq
        }
        sendFeedback ? sendFeedback(request) : null
    }
    const onQuestionChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setQuestion(newValue || "");
    };
    const onAnswerChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setAnswer(newValue || "");
    };
    const closeModalConfirm = () => {
        setShow(false)
        closeFeedback()
    }
    const handleConfirm = async () => {
        const field: string = fieldSelected?.label ? fieldSelected?.label : ''
        const request: IDraftContribute = {
            field, question, correctionAnswer, isDraft: true, messageId: messageId ?? ''
        }
        await draftContribute(request)
        closeModalConfirm()
        closeModalContribute ? closeModalContribute() : null
    }
    const hideModalContribute = () => {
        {
            setShow(true)
        }
    }

    const buttons: ButtonProps[] = [
        {
            children: 'Đóng',
            variant: 'outlined',
            buttonSize: 'sm',
            color: 'error',
            type: 'button',
            fullWidth: false,
            onClick: closeFeedback,
        }
    ]

    const fetchDraftById = async () => {
        const request = {
            id: draftId,
            isDraft: draftStore.activeDraft === DraftStatus.unsent ? true : false
        }
        try {
            const  {data} = await DraftById(request);
            if (data) {
                if(data?.approveStatus && !data?.isDraft) {
                    setIsApproveStatus(true)
                }
              setQuestion(data?.question);
              setAnswer(data?.correctionAnswer);
              setFieldSelected(data.field ? {
                value: data.field,
                label: data.field,
              } : null);
            }
          } catch (error) {
            console.error(error);
          }

    }
    useEffect(() => {
        if (sessionChatsStore.ruleChat === RuleChat.draft && openContribute && draftId) {
            fetchDraftById()
        } else if (sessionChatsStore.ruleChat === RuleChat.draft && openContribute && newMessageId) {
            setQuestion('');
            setAnswer('');
            setFieldSelected(null);
            setIsApproveStatus(false)
        }
    }, [sessionChatsStore.ruleChat, openContribute, draftId])
    useEffect(() => {
        if (!sanitizedAnswerHtml) return
        const parser = new DOMParser();
        const doc = parser.parseFromString(sanitizedAnswerHtml, 'text/html');
        const textWithoutDivs = doc.body.textContent;
        if (textWithoutDivs) {
            const regex = /Xem chi tiết|\(Xem chi tiết\)/g;
            const textWithoutDetails = textWithoutDivs.replace(regex, '');
            setAnswer(textWithoutDetails)
        }
    }, [sanitizedAnswerHtml])

    const handleChangeDoc = () => {
        const _isEmptyDocCitation = checkEmptyDocCitation()
        setIsEmptyDocCitation(_isEmptyDocCitation)
    }

    useEffect(() => {
        handleChangeDoc();
    }, [documentCitation])
    return (
        <div className="">
                <ModalConfirm
                    show={openContribute}
                    title={'Đóng góp ngân hàng câu hỏi (FAQ)'}
                    aria-labelledby="contained-modal-title-center"
                    disabled={!question || !fieldSelected || (isEmptyDocCitation && isEmptyAnswer)}
                    size={"lg"}
                    closeModalConfirm={() => messageId || newMessageId ? hideModalContribute() : closeFeedback()}
                    handleConfirm={handleSubmit}
                    footer={
                        sessionChatsStore.ruleChat === RuleChat.draft && draftStore.activeDraft === DraftStatus.sent && !newMessageId && !messageId
                            ? buttons
                            : null
                    }
                >
                    <Stack>
                        <Stack.Item className="" style={{ marginBottom: '10px' }}>
                            <Label className={`${styles.chatSettingsSeparator} ${styles.labelField}`}>
                                Chọn lĩnh vực
                            </Label>
                            <div>
                                <DropDownField setFieldSelected={setFieldSelected} fieldSelected={fieldSelected} />
                            </div>
                        </Stack.Item>
                        <Stack.Item style={{ marginBottom: '10px' }}>
                            <TextField
                                className={`${styles.chatSettingsSeparator} textfieldCustom`}
                                defaultValue={question}
                                value={question}
                                required={true}
                                label="Câu hỏi"
                                placeholder="Nhập câu hỏi cho hệ thống tại đây"
                                multiline
                                autoAdjustHeight
                                onChange={onQuestionChange}
                            />
                        </Stack.Item>

                        <Stack.Item>
                            <TextField
                                className={`${styles.chatSettingsSeparator} textfieldCustom`}
                                defaultValue={correctionAnswer}
                                value={correctionAnswer}
                                rows={10}
                                required={isEmptyDocCitation}
                                label="Câu trả lời"
                                placeholder="Nhập câu trả lời cho hệ thống tại đây"
                                multiline
                                autoAdjustHeight
                                onChange={onAnswerChange}
                            />
                        </Stack.Item>

                        <Stack.Item className="" style={{ marginBottom: '10px' }}>
                            <Label className={`${styles.chatSettingsSeparator} ${isEmptyAnswer && styles.labelField}`}>
                                Trích dẫn
                            </Label>
                            <div>
                                <CitationForm documentCitation={documentCitation} setDocumentCitation={setDocumentCitation}/>
                            </div>
                        </Stack.Item>
                    </Stack>
                </ModalConfirm>
                <ModalConfirm
                    show={showConfirm}
                    maxWidth="sm"
                    title={"Lưu bản nháp"}
                    aria-labelledby="contained-modal-title-center"
                    closeModalConfirm={closeModalConfirm}
                    handleConfirm={handleConfirm}>
                    {<p>
                        Bạn có muốn lưu bản nháp đóng góp câu trả lời. Xác nhận để tiếp tục hoặc hủy để thoát.
                    </p>}
                </ModalConfirm>
            </div>
    )
});
