import { DialogActions, DialogActionsProps, DialogTitle, DialogTitleProps, Stack, StackProps, styled, SxProps } from '@mui/material'

export const DialogHeaderBasic = styled(Stack, {
  shouldForwardProp: prop => prop !== 'isFullScreen',
})<StackProps>(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: 'transparent',
  justifyContent: 'center',
  '.btn-close': {
    color: 'blueGrey.700',
  },
  '> .MuiTypography-root': {
    textAlign: 'center',
    color: 'blueGrey.600',
  },
}))

export const DialogHeaderFilled = styled(Stack, {
  shouldForwardProp: prop => prop !== 'isFullScreen',
})<StackProps>(({ theme }) => ({
  padding: theme.spacing(2),
  color: 'white',
  justifyContent: 'space-between',
  '.btn-close': {
    color: 'white',
  },
}))

export const closeButton: SxProps = {
  position: 'absolute',
  padding: 0,
  right: 8,
  top: 10,
}

export const DialogActionStyled = styled(DialogActions)<DialogActionsProps>(({ theme }) => ({
  padding: theme.spacing(1),
  borderTop: `1px solid ${theme.palette.divider}`,
}))

export const DialogTitleStyled = styled(DialogTitle)<DialogTitleProps>(({ theme }) => ({
  padding: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
}))
