import React from 'react'
import { DialogTitle as MuiDialogTitle, DialogTitleProps as MuiDialogTitleProps, IconButton } from '@mui/material'
import { DialogTitleStyled } from './Dialog.styles'
import CloseIcon from '@mui/icons-material/Close';

export type DialogTitleProps = {
  onClose?: any
} & MuiDialogTitleProps

const DialogTitle: React.FC<DialogTitleProps> = ({ onClose, children, ...rest }) => {
  return (
    <DialogTitleStyled {...rest}>
      {children}
      <IconButton
        className="btn-close"
        disableRipple
        aria-label="close"
        onClick={onClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}>
        <CloseIcon fontSize="small" sx={{ color: 'var(--color-answer)', fontSize: '2rem' }} />
      </IconButton>
    </DialogTitleStyled>
  )
}

export default DialogTitle
