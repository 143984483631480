import { charts } from "../../../api/models";

interface ChartOptionsProps {
    dataChart: charts[];
    title_chart: string;
    unit_chart: string;
    type_chart: string;
    countColumn: number;
    widthScrollbar: number;
    colors: string[];
}

const ChartOptions = ({
    dataChart,
    title_chart,
    unit_chart,
    type_chart,
    countColumn,
    widthScrollbar,
    colors,
}: ChartOptionsProps) => {
    const commonOptions = {
        title: {
            text: title_chart || '',
        },
    };

    const barOptions = {
        ...commonOptions,
        chart: {
            type: "column",
            scrollablePlotArea: countColumn > 8 ? {
                minWidth: widthScrollbar,
                opacity: 0,
                scrollPositionX: 0,
            } : {},
        },
        xAxis: {
            categories: dataChart.map(item => item.label),
            labels: {
                overflow: 'justify',
            },
        },
        yAxis: {
            title: {
                text: '',
            },
        },
        plotOptions: {
            spline: {
                lineWidth: 4,
                states: {
                    hover: {
                        lineWidth: 5,
                    },
                },
            },
            column: {
                dataLabels: {
                    enabled: true
                }
            }
        },
        series: [{
            name: `${unit_chart ?? 'Giá trị'}`,
            data: dataChart.map(item => item.value),
        }],
    };

    const barRowOptions = {
        chart: {
            type: 'bar',
            events: {
                load: function () {
                    const chart = this as unknown as Highcharts.Chart; // Cast 'this' to Highcharts.Chart
                    const dataLength = chart.series[0].data.length;
                    const newHeight = Math.max(150, dataLength * 40); // Adjust height based on data length
                    chart.setSize(undefined, newHeight, false);
                }
            }
        },
        title: {
            text: title_chart || '',
        },
        xAxis: {
            categories: dataChart.map(item => item.label),
            title: {
                text: null,
            },
        },
        yAxis: {
            min: 0,
            title: {
                text: '',
                align: 'high',
            },
            labels: {
                overflow: 'justify',
            },
            gridLineWidth: 0,
        },
        tooltip: {
            // valueSuffix: ' units',
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: true,
                },
                borderRadius: '50%',
                pointWidth: 18,
                // pointPadding: 0.2,
                // groupPadding: 0.1,
            },
        },
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'top',
            x: -40,
            y: 80,
            // borderWidth: 1,
        },
        series: [{
            name: `${unit_chart ?? 'Giá trị'}`,
            data: dataChart.map(item => item.value),
        }],
    };

    const pieOptions = {
        ...commonOptions,
        chart: {
            type: "pie",
        },
        tooltip: {
            pointFormat: 'Số chỉ tiêu: <b>{point.y}</b><br/>Chiếm tỷ lệ: <b>{point.percentage:.2f}%</b>',
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                colors: colors,
                showInLegend: true,
                dataLabels: {
                    enabled: true,
                    format: '{point.percentage:.2f} %',
                    distance: -50,
                    filter: {
                        property: 'percentage',
                        operator: '>',
                        value: 4,
                    },
                },
            },
        },
        series: [{
            name: "Chỉ tiêu",
            colorByPoint: true,
            data: dataChart.map(point => ({
                name: `${point.label} <span class="custom-text-legend">${point.value ? `(${point.value})` : ''}</span>`,
                y: point.value,
            })),
        }],
    };

    const getChartOptions = () => {
        if (type_chart === 'Bar' && dataChart?.length > 12) {
            return barRowOptions;
        } else if (type_chart === 'Bar') {
            return barOptions;
        } else {
            return pieOptions;
        }
    };
    return getChartOptions()
};

export default ChartOptions;
