import { Box, InputLabel, Stack, TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from "@mui/material";
import { forwardRef } from "react";

export type TextFieldProps = {
  children?: React.ReactNode;
} & MuiTextFieldProps;

export const TextField = ({ children, label, ...rest }: TextFieldProps, ref: React.Ref<any>) => {
  return (
    <Box>
      <Stack direction="row" alignItems="center"sx={{marginBottom: '4px'}}>
        {label && <InputLabel>{label}</InputLabel>}
      </Stack>
      <MuiTextField {...rest} label={null} ref={ref} sx={{width: '100%'}}>
        {children}
      </MuiTextField>
    </Box>
  );
};

export default forwardRef(TextField);
