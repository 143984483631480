import Render from "@components/Ui/Render/Render";
import Stack from "@components/Ui/Stack";
import Highcharts from "highcharts";
import { HighchartsReact } from "highcharts-react-official";
import { useEffect, useMemo, useState } from "react";
import { charts } from "../../../api/models";
import ChartOptions from "./ChartsOptions";
import { ListItem, Skeleton } from '@mui/material'
import { Box, Button } from "@components/Ui";



interface Props {
    dataChart: charts[]
    title_chart: string,
    type_chart: string,
    unit_chart: string
}

const colors = [
    '#ee0033',
    '#6b8abc',
    '#38b3f8',
    '#5ac9c9',
    '#acda7e',
    '#ff8a60'
]

const Charts = ({ dataChart, title_chart, type_chart, unit_chart }: Props) => {
    const [showAll, setShowAll] = useState(false);
    const countColumn = dataChart?.length ?? 0
    const widthScrollbar = countColumn * 40

    const [delayedShowAll, setDelayedShowAll] = useState(showAll);

    useEffect(() => {
        if (!showAll) return
        setDelayedShowAll(showAll);
        const timer = setTimeout(() => {
            setDelayedShowAll(false);
        }, 200);
        return () => clearTimeout(timer);
    }, [showAll]);

    const dataDisplay = useMemo(() => {
        switch (type_chart) {
            case 'Bar':
                if (!showAll && dataChart?.length > 15) {
                    return dataChart.slice(0, 15);
                }
                return dataChart

            default:
                return dataChart
        }

    }, [dataChart, type_chart, showAll])

    const options = ChartOptions({
        dataChart: dataDisplay,
        title_chart,
        type_chart,
        unit_chart,
        countColumn,
        widthScrollbar,
        colors,
    });

    const renderCharts = useMemo(() => {
        return (
            <HighchartsReact highcharts={Highcharts} options={options} />
        )
    }, [delayedShowAll])

    return (
        <Box sx={{marginBottom: '12px'}}>
            <Render
                test={delayedShowAll}
                fallback={renderCharts}
            >
                <Stack direction="column" width="100%">
                    {Array.from({ length: 10 }).map((_, index) => (
                        <ListItem key={index} sx={{ padding: '4px', justifyContent: 'center' }}>
                            <Skeleton height={32} width="70%" />
                        </ListItem>
                    ))}
                </Stack>

            </Render>
            <Render
                test={!showAll && type_chart === 'Bar' && dataChart?.length > 15}
                truthy={
                    <Stack justifyContent="center">
                        <Button
                            variant="text"
                            size="large"
                            sx={{ textAlign: 'center', textTransform: "inherit", letterSpacing: 0 }}
                            onClick={() => setShowAll(true)}
                        >
                            Xem tất cả
                        </Button>
                    </Stack>
                }
            >
            </Render>
        </Box>
    );
};

export default Charts;
