    
import { AskRequest, ChatRequestVtcc, SummaryResponse,AskResponse, ChatRequest, CitationsCompareRequest, PromptRequest, AskResponsePrompt, CitationsRequest, DocumentSupportResponse, DefaultQuestionRequest, requestAddFeedback, IDraftContribute, IDraft, ItemsDraftStatus, CitationsByIdRequest } from "./models";
import {uuid} from "../utils/string";
import { defaultHeader, enterpriseIdLocal, userIdLocal } from "../utils/localStorage";
import eventBus from "../plugins/EventBus";
import { forEach, isEmpty } from "lodash";

export async function askApi(options: AskRequest): Promise<AskResponse> {
    const response = await fetch("https://trolyao.tech.gov.vn/ask", {
        method: "POST",
        headers: {
            ...defaultHeader(),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            question: options.question,
            approach: options.approach,
            overrides: {
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory
            }
        })
    });    
    const parsedResponse: AskResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function chatApi(options: ChatRequest): Promise<AskResponse> {
    const response = await fetch("https://trolyao.tech.gov.vn/chat", {
    // const response = await fetch("https://trolyao.tech.gov.vn/chat", {
        method: "POST",
        headers: {
            ...defaultHeader(),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            history: options.history,
            approach: options.approach,
            fullname: options.fullname,
            username: options.username,
            overrides: {
                semantic_ranker: options.overrides?.semantic_ranker,
                semantic_captions: options.overrides?.semantic_captions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                temperature_gen_query: options.overrides?.temperature_gen_query,
                prompt_template: options.overrides?.prompt_template,
                reference_tab: options.overrides?.reference_tab,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.exclude_category,
                suggest_followup_questions: options.overrides?.suggest_followup_questions,
                use_context_synthesis: options.overrides?.use_context_synthesis,
                semantic_law_retriever: options.overrides?.semantic_law_retriever,
                fulltext_search_retriever: options.overrides?.fulltext_search_retriever,
                chatgpt_model: options.overrides?.chatgpt_model,
                chatgpt_model_gen_query: options.overrides?.chatgpt_model_gen_query,
                no_prompt: options.overrides?.no_prompt,
                no_search_engine: options.overrides?.no_search_engine,
                rerank_retrieve: options.overrides?.rerank_retrieve,
                confirm_decision: options.overrides?.confirm_decision,
                use_llm_filter: options.overrides?.use_llm_filter,
                use_llm_filter_reference: options.overrides?.use_llm_filter_reference,
                suggestion_by_llm: options?.overrides?.suggestion_by_llm,
                no_gen_query: options.overrides?.no_gen_query,
                retrieval_mode: options.overrides?.retrieval_mode,
                prefix_prompt: options.overrides?.prefix_prompt,
                follow_up_prompt: options.overrides?.follow_up_prompt,
                query_prompt: options.overrides?.query_prompt,
                graph_prompt: options.overrides?.graph_prompt,
                conversation_id: options.overrides?.conversation_id,
                use_whitelist: options.overrides?.use_whitelist,
                google_seo: options.overrides?.google_seo,
                use_knowledge_graph: options.overrides?.use_knowledge_graph,
                graph_have_relationship: options.overrides?.graph_have_relationship,
                // knowledge_graph_option: options.overrides?.knowledge_graph_option,
                rule_search: options.overrides?.rule_search,
                level_search: options.overrides?.level_search,
                last_is_legal: options.overrides?.last_is_legal,
                need_search_specific: options.overrides?.need_search_specific,
                continue_step_search: options?.overrides?.continue_step_search,
                expanded_search: options?.overrides?.expanded_search,
                payload: options?.overrides?.payload,
                custom_enterprise_id: enterpriseIdLocal(),
                is_used_history: options.overrides?.is_used_history,
                use_confirm_answer: options.overrides?.use_confirm_answer,
                type_hybrid_search: options.overrides?.type_hybrid_search,
                type_search_engine: options.overrides?.type_search_engine,
            }
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    if (response.status === 401) {
        eventBus.dispatch('dispatch-unauthorized', {})
    }

    return parsedResponse;
}

export async function chatApiVtccFlow(options: ChatRequest): Promise<AskResponse> {
    const response = await fetch("https://trolyao.tech.gov.vn/vtcc_llm_chat", {
        method: "POST",
        headers: {
            ...defaultHeader(),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            query: options.query,
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    if (response.status === 401) {
        eventBus.dispatch('dispatch-unauthorized', {})
    }

    return parsedResponse;
}

export async function getConfiguration(): Promise<any[]> {
    const response = await fetch("https://trolyao.tech.gov.vn/settings?username=namph13", {
        headers: {
            ...defaultHeader()
        },
    });

    const parsedResponse: AskResponsePrompt[] = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error("Unknown error");
    }
    
    return parsedResponse;
}

export async function citationsApi(options: any): Promise<AskResponse> {
    const response = await fetch("https://trolyao.tech.gov.vn/content", {
    method: "POST",
    headers: {
        ...defaultHeader(),
        "Content-Type": "application/json"
    },
    body: JSON.stringify({
     ...options,
     enterpriseId: enterpriseIdLocal()
    })
    });
    
    const parsedResponse: AskResponse = await response.json();
    if (response.status > 299 || !response.ok) {
    throw Error(parsedResponse.error || "Unknown error");
    }
    
    return parsedResponse;
}
export async function citationsByIdApi(options: CitationsByIdRequest): Promise<AskResponse> {
    const response = await fetch("https://trolyao.tech.gov.vn/content-by-id", {
        method: "POST",
        headers: {
            ...defaultHeader(),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            ...options
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}
export async function citationsPopupApi(options: CitationsRequest): Promise<AskResponse> {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 6000);
    try {
        const response = await fetch("https://trolyao.tech.gov.vn/content", {
        method: "POST",
        headers: {
            ...defaultHeader(),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
        content: options.content,
        enterpriseId: enterpriseIdLocal()
        }),
        signal: controller.signal
        });
        clearTimeout(timeoutId);
        const parsedResponse: AskResponse = await response.json();
        if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
        }
        return parsedResponse;
    } catch (error) {
        throw new Error('API request timed out after 6 seconds');
    }
}

export async function citationsCompareApi(options: CitationsCompareRequest): Promise<AskResponse> {
    const token = "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ1c2VyLnZ0Y2MiLCJleHAiOjE4MDc0NDEzNTMsInNjb3BlIjpbXX0.pXNIDbFBM4YBMsjOHr_WwBBjTBobkE6eG9rCCN-07NIa5NAYc1J_Pdz32hZkh35NlRcemfTzfqMfavHD2upxrg"
    const response = await fetch("https://gateway-congchuc.trolyao.org/api/judgment/compareArticle", {
    method: "POST",
    headers: {
        ...defaultHeader(),
        "Content-Type": "application/json",
        "Authorization" : `Bearer ${token}`
    },
    body: JSON.stringify({
    word: options.word,
    textList: options.textList
    })
    });
    
    const parsedResponse: AskResponse = await response.json();
    if (response.status > 299 || !response.ok) {
    throw Error(parsedResponse.error || "Unknown error");
    }
    
    return parsedResponse;
}

export async function EditPrompt(options: PromptRequest): Promise<AskResponsePrompt> {
    const response = await fetch(`https://trolyao.tech.gov.vn/prompts/${options?.idPrompt}`, {
        method: "POST",
        headers: {
            ...defaultHeader(),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            bot: options?.bot,
            password: options?.password,
            prefix_prompt: options?.prefixPrompt,
            follow_up_prompt: options?.followUpPrompt,
            query_prompt: options?.queryPrompt,
            graph_prompt: options?.graphPrompt,
            is_apply: true
        })
    });

    const parsedResponse: AskResponsePrompt = await response.json();
    if (response.status > 299 || !response.ok) {
        // throw Error(parsedResponse.error || "Unknown error");
        return Promise.reject(parsedResponse.error || "Unknown error")
    }

    return parsedResponse;
}

export async function setDefaultPrompt(options: PromptRequest): Promise<AskResponsePrompt> {
    const response = await fetch("https://trolyao.tech.gov.vn/prompts/default", {
        method: "POST",
        headers: {
            ...defaultHeader(),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            id: options?.idPrompt,
            password: options?.password
        })
    });

    const parsedResponse: AskResponsePrompt = await response.json();
    if (response.status > 299 || !response.ok) {
        // throw Error(parsedResponse.error || "Unknown error");
        return Promise.reject(parsedResponse.error || "Unknown error")
    }

    return parsedResponse;
}

export async function getPrompt(): Promise<AskResponsePrompt[]> {
    const response = await fetch("https://trolyao.tech.gov.vn/prompts", {
        headers: {
            ...defaultHeader()
        },
    });

    const parsedResponse: AskResponsePrompt[] = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error("Unknown error");
    }
    
    return parsedResponse;
}
export async function getDefaultQuestion(): Promise<any> {
    const userString = localStorage.getItem('_user') || '{}';
    const user = JSON.parse(userString);
    
    if (!user?.enterpriseId) {
        throw new Error('Missing enterprise ID in user data');
    }

    const response = await fetch("https://trolyao.tech.gov.vn/list-chat-sample/enterprise", {
        method: "POST",
        headers: {
            ...defaultHeader(),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            // type: "TLACC",
            type_sample: "new",
            enterprise_id: user.enterpriseId,
            // enterprise_id: "65b32896f66581071243c63a",
        })
    });

    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error("Unknown error");
    }
    
    return parsedResponse;
}

export async function EditDefaultQuestion(options: DefaultQuestionRequest): Promise<AskResponsePrompt> {
    const response = await fetch(`https://trolyao.tech.gov.vn/chat-sample/${options?.id}`, {
        method: "POST",
        headers: {
            ...defaultHeader(),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            type: options?.type,
            data: options?.data,
        })
    });

    const parsedResponse: AskResponsePrompt = await response.json();
    if (response.status > 299 || !response.ok) {
        // throw Error(parsedResponse.error || "Unknown error");
        return Promise.reject(parsedResponse.error || "Unknown error")
    }

    return parsedResponse;
}
export function getCitationFilePath(citation: string): string {
    return `/content/${citation}`;
}

export async function getLawDocumentsApi(): Promise<DocumentSupportResponse> {
    const response = await fetch("https://gateway-congchuc.trolyao.org/api/judgment/getLawDocumentByTypes", {
        headers: {
            ...defaultHeader()
        },
    });

    const parsedResponse: DocumentSupportResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function listAllChatSession(requestGet: any): Promise<any> {
    // const response = await fetch("https://gateway-congchuc.trolyao.org/api/chatSession/listAllChatSession", {
    const response = await fetch("https://gateway-congchuc.trolyao.org/api/chatSession/allChats", {

    method: "POST",
    headers: {
        ...defaultHeader(),
        "Content-Type": "application/json"
    },
    body: JSON.stringify({
        userId: requestGet.userId
    })
    });

    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
    throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function listAllDraft(request: any): Promise<any> {
    // const response = await fetch("https://gateway-congchuc.trolyao.org/api/chatSession/listAllChatSession", {
    const response = await fetch(`https://gateway-congchuc.trolyao.org/api/chatSession/getAffairAsFeedback?userId=${userIdLocal()}&isDraft=${request}`, {
        method: "POST",
        headers: {
            ...defaultHeader(),
            "Content-Type": "application/json"
        }
    });

    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function DraftById(request: any): Promise<any> {
    const response = await fetch(`https://gateway-congchuc.trolyao.org/api/chatSession/getAffairAsFeedbackById?feedbackId=${request.id}&isDraft=${request.isDraft}`, {
    method: "POST",
    headers: {
        ...defaultHeader(),
        "Content-Type": "application/json"
    }
    });

    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
    throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function addChatMessages(requestAdd: any): Promise<any> {
    const response = await fetch("https://gateway-congchuc.trolyao.org/api/chatSession/addChatMessage", {
    method: "POST",
    headers: {
        ...defaultHeader(),
        "Content-Type": "application/json"
    },
    body: JSON.stringify({
        question: requestAdd?.question,
        senderId: requestAdd?.senderId,
        sessionId: requestAdd?.sessionId,
        data_points: requestAdd?.data_points,
        semantic_scores: requestAdd?.semantic_scores,
        rerank_scores: requestAdd?.rerank_scores,
        thoughts: requestAdd?.thoughts,
        debugs: requestAdd?.debugs,
        answer: requestAdd?.answer,
        reference_urls: requestAdd?.reference_urls,
        reference_docs: requestAdd?.reference_docs,
        reference_titles: requestAdd?.reference_titles,
        gen_query: requestAdd?.gen_query,
        is_legal: requestAdd?.is_legal,
        buttons: requestAdd?.buttons,
        recommend_buttons: requestAdd?.recommend_buttons,
        documents: requestAdd?.documents,
        recommends: requestAdd?.recommends,
        not_show_citation: requestAdd?.not_show_citation,
        need_search_specific: requestAdd?.need_search_specific,
        track_search: requestAdd?.track_search,
        continue_step_search: requestAdd?.continue_step_search,
        continue_step_search_title: requestAdd?.continue_step_search_title,
        expanded_search: requestAdd?.expanded_search,
        is_expanded_search: requestAdd?.is_expanded_search,
        faqs: requestAdd?.faqs,
        global_articles: requestAdd?.global_articles,
        specific_articles: requestAdd?.specific_articles,
        use_fixed_citation: requestAdd?.use_fixed_citation,
        working_process_steps: requestAdd?.working_process_steps,
        process_images: requestAdd?.process_images,
        data_chart: requestAdd?.data_chart,
        title_chart: requestAdd?.title_chart,
        type_chart: requestAdd?.type_chart,
        unit_chart: requestAdd?.unit_chart,
        multiple_data_points: requestAdd?.multiple_data_points,
        is_used_history: requestAdd?.is_used_history,
        use_list_citation: requestAdd?.use_list_citation,
        searching_type: requestAdd?.searching_type,
        show_button_reference: requestAdd?.show_button_reference,
        is_markdown: requestAdd?.is_markdown
    })
    });

    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
    throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function createChatSession(requestCreate: any): Promise<any> {
    const response = await fetch("https://gateway-congchuc.trolyao.org/api/chatSession/createChatSession", {
    method: "POST",
    headers: {
        ...defaultHeader(),
        "Content-Type": "application/json"
    },
    body: JSON.stringify({
        sessionName: requestCreate?.sessionName,
        userId: requestCreate?.userId,
    })
    });

    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
    throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function saveFeedback(requestAdd: any): Promise<any> {
    const response = await fetch("https://gateway-congchuc.trolyao.org/api/chatSession/saveFeedback", {
    method: "POST",
    headers: {
        ...defaultHeader(),
        "Content-Type": "application/json"
    },
    body: JSON.stringify({
        question: requestAdd?.question,
        messageId: requestAdd?.messageId,
        isDraft: requestAdd?.isDraft,
        field: requestAdd?.field,
        correctionAnswer: requestAdd?.correctionAnswer,
        id: requestAdd?.id,
        userId: userIdLocal(),
        approveStatus: 'DANG_DUYET',
        enterpriseId: enterpriseIdLocal(),
        documentCitation: requestAdd?.documentCitation
    })
    });

    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
    throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}
export async function initDefaultChat(requestCreate: any): Promise<any> {
    const response = await fetch("https://gateway-congchuc.trolyao.org/api/chatSession/initChat", {
    method: "POST",
    headers: {
        ...defaultHeader(),
        "Content-Type": "application/json"
    },
    body: JSON.stringify({
        userId: requestCreate?.userId,
        question: requestCreate?.question
    })
    });

    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
    throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function editChatSession(request: any): Promise<any> {
    const response = await fetch("https://gateway-congchuc.trolyao.org/api/chatSession/editChatSession", {
        method: "POST",
        headers: {
            ...defaultHeader(),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            sessionName: request?.sessionName,
            sessionId: request?.sessionId
        })
    });

    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function pinChatSession(request: any): Promise<any> {
    const response = await fetch("https://gateway-congchuc.trolyao.org/api/chatSession/pinChatSession", {
    method: "POST",
    headers: {
        ...defaultHeader(),
        "Content-Type": "application/json"
    },
    body: JSON.stringify({
        pinned: request?.pinned,
        sessionId: request?.sessionId
    })
    });

    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
    throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function chatSessionById(request: any): Promise<any> {
    const response = await fetch("https://gateway-congchuc.trolyao.org/api/chatSession/listMessagesBySessionId", {
    method: "POST",
    headers: {
        ...defaultHeader(),
        "Content-Type": "application/json"
    },
    body: JSON.stringify({
        sessionId: request?.sessionId
    })
    });

    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
    throw Error(parsedResponse.error || "Unknown error");
    }
    return parsedResponse;
}

export async function deleteChatSession(request: any): Promise<any> {
    const response = await fetch("https://gateway-congchuc.trolyao.org/api/chatSession/deleteChatSession", {
        method: "POST",
        headers: {
            ...defaultHeader(),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            sessionId: request?.sessionId
        })
    });

    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function deleteAllChatSession(request: any): Promise<any> {
    const response = await fetch("https://gateway-congchuc.trolyao.org/api/chatSession/deleteAllChatSession", {
    method: "POST",
    headers: {
        ...defaultHeader(),
        "Content-Type": "application/json"
    },
    body: JSON.stringify({
        userId: request?.userId
    })
    });

    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
    throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}
export async function likeUnlikeChatSession(request: any): Promise<any> {
    const response = await fetch("https://gateway-congchuc.trolyao.org/api/chatSession/likeMessage", {
    method: "POST",
    headers: {
        ...defaultHeader(),
        "Content-Type": "application/json"
    },
    body: JSON.stringify({
        like: request?.like,
        messageId: request?.messageId,
        reasonDislike: request?.reasonDislike,
        reasonLike: request?.reasonLike,
        correctionAnswer: request?.correctionAnswer
    })
    });

    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
    throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function rateMessageChatSession(request: any): Promise<any> {
    const formData = new FormData();

    if (!isEmpty(request?.files)) {
        forEach(request?.files, (file: any) => {
            formData.append("attachments", file);
        });
    }
    formData.append("messageId", request?.messageId);
    formData.append("star", request?.star);
    if (request?.reasonType) {
        formData.append("reasonType", request.reasonType);
    }
    if (request?.description) {
        formData.append("description", request.description);
    }

    if (request?.documentCitationsAsJson) {
        formData.append("documentCitationsAsJson", request.documentCitationsAsJson);
    }

    const response = await fetch("https://gateway-congchuc.trolyao.org/api/chatSession/starReview", {
        method: "POST",
        headers: {
            ...defaultHeader()
        },
        body: formData
    });

    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function deleteFeedbackById(request: any): Promise<any> {
    const response = await fetch(`https://gateway-congchuc.trolyao.org/api/chatSession/deleteFeedbackById?feedbackId=${request?.feedbackId}`, {
        method: "POST",
        headers: {
            ...defaultHeader(),
            "Content-Type": "application/json"
        }
    });

    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function clearMessagesInChatSession(request: any): Promise<any> {
    const response = await fetch("https://gateway-congchuc.trolyao.org/api/chatSession/clearMessagesInChatSession", {
    method: "POST",
    headers: {
        ...defaultHeader(),
        "Content-Type": "application/json"
    },
    body: JSON.stringify({
        sessionId: request?.sessionId
    })
    });

    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
    throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}
export async function uploadFileApi(data: any): Promise<any> {
    const formData = new FormData()
    let userId = localStorage.getItem('uuid')
    if (!userId) {
        userId = uuid()
        localStorage.setItem('uuid', userId)
    }

    formData.append("file", data.file);
    formData.append("user_id", userId);

    const response = await fetch("https://trolyao.tech.gov.vn/create-temp-conversation", {
        method: "POST",
        headers: {
            ...defaultHeader()
        },
        body: formData
    });

    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
        // throw Error(parsedResponse.error || "Unknown error");
        return Promise.reject(parsedResponse.error || "Unknown error")
    }

    return parsedResponse;
}

export async function getLawDiagram(requestGet: any): Promise<any> {
    // const response = await fetch("https://gateway-congchuc.trolyao.org/api/chatSession/listAllChatSession", {
    const response = await fetch(`https://gateway-phapluat.trolyao.org/document/get-diagrams/${requestGet.id}`, {

    method: "GET",
    headers: {
        ...defaultHeader(),
        "Content-Type": "application/json"
    }
    });

    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
    throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}
export async function getEnterprise(enterpriseId: string): Promise<any> {
    const response = await fetch("https://gateway-phapluat.trolyao.org/configuration-customer/get-enterprise", {
        method: "POST",
        headers: {
            ...defaultHeader(),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            enterpriseId: enterpriseId
        })
    });

    const parsedResponse: any = await response.json();
    
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function getSuggestions(requestCreate: any, url: string): Promise<any> {
    const response = await fetch(url, {
        method: "POST",
        headers: {
            ...defaultHeader(),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            word: requestCreate?.word,
            is_new_semantic: requestCreate?.is_new_semantic,
            enterpriseId: requestCreate?.enterpriseId
        })
    });

    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function getSuggestionsV2(requestCreate: any, url: string): Promise<any> {
    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(requestCreate)
    });

    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function getTargetData(intension: any): Promise<any> {
    const response = await fetch(`https://tlacc-admin.trolyao.org/api/target-data/get-by-name?name=${intension}`, {
        method: "GET",
        headers: {
            ...defaultHeader(),
            "Content-Type": "application/json"
        }
    });

    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function getByGroup(group: string, field?: string): Promise<any> {
    const suffURL = !isEmpty(field) ? `&field=${field}` : ''
    const response = await fetch(`https://gateway-tlacn.cyberbot.vn/api/chatbot/faq/issue/getByGroup?group=${group}${suffURL}`, {
        method: "GET",
        headers: {
            ...defaultHeader(),
            "Content-Type": "application/json"
        }
    });

    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function summaryApi(options: ChatRequest): Promise<SummaryResponse> {
    const response = await fetch("https://trolyao.tech.gov.vn/chat", {
        method: "POST",
        headers: {
            ...defaultHeader(),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            history: options.history,
            approach: options.approach,
            fullname: options.fullname,
            username: options.username,
            overrides: {
                chatgpt_model: options.overrides?.chatgpt_model,
                chatgpt_model_gen_query: options.overrides?.chatgpt_model_gen_query,
                summary_only: true
            }
        })
    });

    const parsedResponse: SummaryResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error("Unknown error");
    }

    if (response.status === 401) {
        eventBus.dispatch("dispatch-unauthorized", {});
    }

    return parsedResponse;
}

export async function getLawSelectList(option: any): Promise<any> {
    const response = await fetch("https://gateway-phapluat.trolyao.org/document/get-law-select-list", {
        method: "POST",
        headers: {
            ...defaultHeader(),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            page: 0,
            enterpriseId: enterpriseIdLocal(),
            keyword: option?.keyword
        })
    });

    const parsedResponse: any = await response.json();
    
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}
export async function getArticleSelectList(option: any): Promise<any> {
    const response = await fetch("https://gateway-phapluat.trolyao.org/document/get-content-for-citation", {
        method: "POST",
        headers: {
            ...defaultHeader(),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            page: 0,
            documentId: option?.documentId,
            keyword: option?.keyword
        })
    });

    const parsedResponse: any = await response.json();
    
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}