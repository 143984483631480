import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route, Router, BrowserRouter } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import eventBus from "./plugins/EventBus"
import { Provider } from 'react-redux'
import { store } from './redux/store'

import 'bootstrap/dist/css/bootstrap.min.css';
import "./index.css";

import Login from "./pages/login/Login"
import Register from "./pages/register/Register"
import AuthCallback from "./pages/auth/Callback";
import Layout from "./pages/layout/Layout";
import NoPage from "./pages/NoPage";
import OneShot from "./pages/oneshot/OneShot";
// import Chat from "./pages/chat/Chat";
import Upload from "./pages/upload/Upload";
import ChatWithSubject from "./pages/chat-with-subject/ChatWithSubject";
import { ChatWithHistory } from "./pages/chat-with-history/ChatWithHistory";
import { isShowConfiguration } from "./utils/isShowConfiguration";
import { defaultHeader } from "./utils/localStorage";
import useLocalStorage from 'use-local-storage';

import axios from "axios";
import { themeChange } from "./utils/theme";
import { eventTime } from "./utils/dateConvert";

import { ToastContainer } from 'react-toastify';
import CustomSnackbarProvider from "./context/CustomSnackBar";
import { ThemeProvider } from '@emotion/react'
import { CssBaseline } from "@mui/material";
import lightTheme from './styles/theme/light'
import darkTheme from "./styles/theme/dark"
import { ColorModeProvider, useColorMode } from "./components/context/ColorModeContext";


initializeIcons();

export default function App() {
    const childRef = useRef(null);
    const [token, setToken] = useState(localStorage.getItem('_token'))
    const [showHeader, setHeader] = useState(true)
    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false)
    const [messageRequest, setMessageRequest] = useState<string>("")

    const { colorMode } = useColorMode()
    const timeLight = eventTime().hour >= 6 && eventTime().hour < 15

    const storedTheme = localStorage.getItem("theme");

    const prefersDark =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches;
    
    const defaultDark =
    // storedTheme === "dark" || (storedTheme === null && prefersDark) || (storedTheme === null && !timeLight);
    (storedTheme === null && prefersDark) || (storedTheme === "dark" && !timeLight);
    
    if (defaultDark) {
        themeChange('dark');
    } else {
        themeChange('light');
    }

    const onExampleClicked = (event: any) => {
        setMessageRequest(event.target.innerText)
    }


    // event bus
    const firstUpdate = useRef(false);
    useEffect(() => {
        if (firstUpdate.current) {
            return;
        }
        eventBus.on("onClickNavLinkExperience", (data: any) => {
            setHeader(true)
        });
        firstUpdate.current = true;
        verifyUserSso()
        return () => {};
    }, [])

    const verifyUserSso = async () => {
        if (token) {
            const url = window.location.href
            if (url && url.includes('/auth/callback')) {
                window.location.replace('/#/chat');
                return;
            }
            try {
                const {data} = await axios.post('https://trolyao.tech.gov.vn/auth/verify-token', {}, {
                    headers: {
                        ...defaultHeader()
                    }
                })
                if (data && data.status) {
                    return;
                } else {
                    logout();
                }
            } catch (e) {
                console.log(e)
                logout();
            }
        }
    };

    const logout = async () => {
        try {
            await axios.post('https://trolyao.tech.gov.vn/auth/logout', {}, {
                headers: {
                    ...defaultHeader()
                }
            })
        } catch (e) {
            console.log(e)
        }

        const typeAuthorization = localStorage.getItem('_typeAuthorization')
        const id_token_hind = localStorage.getItem('_refresh_token')
        localStorage.removeItem('_token')
        localStorage.removeItem('_user')
        localStorage.removeItem('_refresh_token')
        localStorage.removeItem('_typeAuthorization')
        localStorage.removeItem("_configuaration")
        localStorage.removeItem("_enterpriseIdLocal")
        setHeader(true)
        setToken('');

        if (typeAuthorization && typeAuthorization === 'OTHER') {
            window.location.replace(`/#/?message_sso=${encodeURI('Bạn không nằm trong danh sách đăng ký thử nghiệm')}`);
            // window.location.href = `https://beta-sso.mic.gov.vn/connect/endsession?id_token_hint=${id_token_hind}&post_logout_redirect_uri=https://trolyao.tech.gov.vn`;
        } 
        // else {
        //     const message = 'Phiên làm việc của bạn đã bị hết hạn. Vui lòng đăng nhập lại.'
        //     window.location.replace(`/#/login?message=${message}`);
        // }
    };

    if (!token) {
        return (
          <HashRouter>
            <Routes>
              <Route path="/" element={<Layout showHeader={showHeader} onClickMenu={() => setIsConfigPanelOpen(!isConfigPanelOpen)} onExampleClicked={() => onExampleClicked(event)} token={token} setToken={(val: any) => setToken(val)} setHeader={(val: any) => setHeader(val)}/>}></Route>
              <Route path="/login" element={<Login setToken={(val: any) => setToken(val)} setHeader={(val: any) => setHeader(val)}/>} />
              {/* <Route path="/Ha-Giang" element={<Register setToken={(val: any) => setToken(val)} setHeader={(val: any) => setHeader(val)}/>} /> */}
                <Route path="*" element={<NoPage />} />
                <Route path="/auth/callback" element={<AuthCallback setToken={(val: any) => setToken(val)} setHeader={(val: any) => setHeader(val)}/>} />
            </Routes>
          </HashRouter>
        );
    }
    return (
        <HashRouter>
            <Routes>
                <Route path="/" element={<Layout showHeader={showHeader} onClickMenu={() => setIsConfigPanelOpen(!isConfigPanelOpen)} onExampleClicked={() => onExampleClicked(event)} token={token} setToken={(val: any) => setToken(val)} setHeader={(val: any) => setHeader(val)}/>}>
                    <Route path="chat" element={<ChatWithHistory showHeader={showHeader} setHeader={(val: any) => setHeader(val)} messageRequest={messageRequest} setMessageRequest={setMessageRequest} isConfigPanelOpen={isConfigPanelOpen}/>}/>
                    <Route path="chat/:id" element={<ChatWithHistory showHeader={showHeader} setHeader={(val: any) => setHeader(val)} messageRequest={messageRequest} setMessageRequest={setMessageRequest} isConfigPanelOpen={isConfigPanelOpen}/>}/>
                    {/* <Route path="qa" element={<OneShot />} /> */}
                    <Route path="*" element={<NoPage />} />
                    {
                        isShowConfiguration() && (
                            <>
                                <Route path="experience" element={<Upload showHeader={showHeader} setHeader={(val: any) => setHeader(val)}/>} />
                                <Route path="experience/chat" element={<ChatWithSubject setHeader={(val: any) => setHeader(val)} messageRequest={messageRequest} setMessageRequest={setMessageRequest} isConfigPanelOpen={isConfigPanelOpen} setIsConfigPanelOpen={setIsConfigPanelOpen}/>} />
                            </>
                        )
                    }
                </Route>
            </Routes>
        </HashRouter>
    )
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <Provider store={store} >
            <ToastContainer />
            <CustomSnackbarProvider>
                <App />
            </CustomSnackbarProvider>
        </Provider>
    </React.StrictMode>
);
